import {
  NDEFMessage,
  NfcJobType,
  PostNfcJobRequest,
  NfcJob,
  NfcJobState,
  NDEFRecord,
  NfcDevice,
} from "./openapi/kitchenaid/nfc/api";
import { KitchenAid } from "./KitchenAid";
import { getKitchenAidId, Station } from "./Settings";
import { ProductSerial } from "./erp/manufacturing/Product";
import { isBlank } from "./StringUtils";
import { DeviceType } from "./bff/LABEL_API";
import {
  BF_BYTEFLIES_KIT,
  BF_BYTEFLIES_KIT_MODEL_2,
  BF_COVIDCARE_BOX_1,
  BF_COVIDCARE_BOX_2,
  BF_NAKED_2SNAPCRADLE,
  BF_NAKED_2SNAPCRADLE_110,
  BF_NAKED_3SNAPCRADLE_LEFT,
  BF_NAKED_3SNAPCRADLE_RIGHT,
  BF_NAKED_3WIRECRADLE_LEFT,
  BF_NAKED_3WIRECRADLE_RIGHT,
  BF_NAKED_4SNAPCRADLE,
  BF_NAKED_4WIRE_CRADLE,
  BF_NAKED_DOCK,
  BF_NAKED_DOT,
  BF_NAKED_ECG_3SNAPCRADLE,
  BF_PACKAGED_2SNAPCRADLE,
  BF_PACKAGED_3SNAPCRADLE_LEFT,
  BF_PACKAGED_3SNAPCRADLE_RIGHT,
  BF_PACKAGED_3WIRECRADLE_LEFT,
  BF_PACKAGED_3WIRECRADLE_RIGHT,
  BF_PACKAGED_4SNAPCRADLE,
  BF_PACKAGED_4WIRE_CRADLE,
  BF_PACKAGED_DOT,
  hasNfcWithId,
} from "./Utils";

const NFC_DEVICE_ID = process.env.REACT_APP_NFC_DEVICE_ID;

export const generateNdefRecords = (serial: ProductSerial): NDEFRecord[] => {
  const bfNumber = serial?.product?.internalReference;
  if (isBlank(bfNumber)) {
    throw new Error("Product BF-number is blank");
  }

  const product = bfNumberToDeviceId(bfNumber!);
  if (isBlank(product)) {
    throw new Error(`Unknown product type for nfc: ${bfNumber}`);
  }

  const records: NDEFRecord[] = [
    {
      recordType: "mime",
      mediaType: `${product}/UDI`.toLowerCase(),
      data: serial.udi,
    },
  ];

  if (hasNfcWithId(bfNumber!)) {
    const deviceId = serial.deviceId;
    if (isBlank(deviceId)) {
      throw new Error(
        `Device ID is blank for nfc: ${serial.serial} ${serial.deviceId} ${bfNumber}`
      );
    }

    records.push({
      recordType: "mime",
      mediaType: `${product}/ID`.toLowerCase(),
      data: deviceId,
    });
  }
  return records;
};

const bfNumberToDeviceId = (bfNumber: string): DeviceType | undefined => {
  switch (bfNumber) {
    case BF_NAKED_DOT:
    case BF_PACKAGED_DOT:
      return "Sensor-Dot";
    case BF_NAKED_DOCK:
    case BF_BYTEFLIES_KIT:
    case BF_BYTEFLIES_KIT_MODEL_2:
    case BF_COVIDCARE_BOX_1:
    case BF_COVIDCARE_BOX_2:
      return "Docking-Station";
    case BF_NAKED_2SNAPCRADLE:
    case BF_NAKED_2SNAPCRADLE_110:
    case BF_PACKAGED_2SNAPCRADLE:
      return "2-Snap-Cradle";
    case BF_NAKED_3SNAPCRADLE_LEFT:
    case BF_PACKAGED_3SNAPCRADLE_LEFT:
      return "3-Snap-Cradle-Left";
    case BF_NAKED_3SNAPCRADLE_RIGHT:
    case BF_PACKAGED_3SNAPCRADLE_RIGHT:
      return "3-Snap-Cradle-Right";
    case BF_NAKED_4WIRE_CRADLE:
    case BF_PACKAGED_4WIRE_CRADLE:
      return "ExG-Patch";
    case BF_NAKED_4SNAPCRADLE:
    case BF_PACKAGED_4SNAPCRADLE:
      return "4-Snap-Cradle";
    case BF_NAKED_ECG_3SNAPCRADLE:
      return "ECG-3-Snap-Cradle";
    case BF_NAKED_3WIRECRADLE_LEFT:
    case BF_PACKAGED_3WIRECRADLE_LEFT:
      return "3-Wire-Cradle-Left";
    case BF_NAKED_3WIRECRADLE_RIGHT:
    case BF_PACKAGED_3WIRECRADLE_RIGHT:
      return "3-Wire-Cradle-Right";
    default:
      return undefined;
  }
};

const getNfcDeviceId = async (kitchenAid: KitchenAid, station: Station) => {
  if (NFC_DEVICE_ID) {
    console.log(`Printer ID set via environmental variable: ${NFC_DEVICE_ID}`);
    return NFC_DEVICE_ID;
  }
  const kitchenAidId = getKitchenAidId(station);
  const nfcDevices: NfcDevice[] = await kitchenAid.listNfcDevices(kitchenAidId);
  if (nfcDevices.length === 0) {
    throw new Error("No NFC devices are available");
  }
  console.log(`
    Found NFC devices:
    ${JSON.stringify(nfcDevices)}
  `);
  const kitchenAidNfcDevices = nfcDevices.filter(
    (p) => p.computer.id === kitchenAidId
  );
  if (kitchenAidNfcDevices.length === 0) {
    throw new Error(
      `No NFC devices are available for '${station}' on '${kitchenAidId}'`
    );
  }
  return kitchenAidNfcDevices[0].id;
};

const sleep = async (ms: number) => {
  await new Promise((r) => setTimeout(r, ms));
};

export const readNfc = async (station: Station): Promise<NDEFMessage> => {
  const test = ["test", "test2"].includes(station) ? true : false;
  const kitchenAid = new KitchenAid(test);
  const nfcDeviceId = await getNfcDeviceId(kitchenAid, station);

  const nfcJobRequest: PostNfcJobRequest = {
    deviceId: nfcDeviceId,
    type: NfcJobType.Read,
  };
  console.log(`Reading NFC from device '${nfcDeviceId}'`);
  const createdNfcJob = await kitchenAid.createNfcJob(nfcJobRequest);
  console.log(`NFC job ID: ${createdNfcJob.id}`);
  if (
    createdNfcJob?.state === NfcJobState.Done &&
    createdNfcJob?.message !== undefined
  ) {
    return createdNfcJob.message!;
  } else if (createdNfcJob?.state === NfcJobState.Error) {
    const msg = `NFC job state: ${createdNfcJob?.state}`;
    console.error(msg);
    throw new Error(msg);
  }

  let retries = -1;
  let nfcJob: NfcJob;
  let state: NfcJobState | undefined;
  while (retries !== 10) {
    retries++;
    try {
      nfcJob = await kitchenAid.readNfcJob(createdNfcJob.id);
    } catch (e) {
      console.error("polling", e);
      continue;
    }
    state = nfcJob.state;
    if (
      state === NfcJobState.Done ||
      state === NfcJobState.Error ||
      state === NfcJobState.Expired
    ) {
      break;
    }
    await sleep(100);
  }
  if (state === NfcJobState.Done) {
    return nfcJob!.message!;
  } else {
    const msg = `NFC job state: ${state}`;
    console.error(msg);
    throw new Error(msg);
  }
};

export const writeNfc = async (station: Station, records: NDEFRecord[]) => {
  const test = ["test", "test2"].includes(station) ? true : false;
  const kitchenAid = new KitchenAid(test);
  const nfcDeviceId = await getNfcDeviceId(kitchenAid, station);

  const nfcJobRequest: PostNfcJobRequest = {
    deviceId: nfcDeviceId,
    type: NfcJobType.Write,
    records: records,
    options: {
      protect: test ? false : true,
    },
  };
  console.log(`Writing NFC on device '${nfcDeviceId}'`);
  const createdNfcJob = await kitchenAid.createNfcJob(nfcJobRequest);
  console.log(`NFC job ID: ${createdNfcJob.id}`);
  if (createdNfcJob?.state === NfcJobState.Done) {
    return;
  } else if (createdNfcJob?.state === NfcJobState.Error) {
    const msg = `NFC job state: ${createdNfcJob?.state}`;
    console.error(msg);
    throw new Error(msg);
  }

  let retries = -1;
  let nfcJob: NfcJob;
  let state: NfcJobState | undefined;
  while (retries !== 10) {
    retries++;
    try {
      nfcJob = await kitchenAid.readNfcJob(createdNfcJob.id);
    } catch (e) {
      console.error("polling", e);
      continue;
    }
    state = nfcJob.state;
    if (
      state === NfcJobState.Done ||
      state === NfcJobState.Error ||
      state === NfcJobState.Expired
    ) {
      break;
    }
    await sleep(100);
  }
  if (state === NfcJobState.Done) {
    return;
  } else {
    const msg = `NFC job state: ${state}`;
    console.error(msg);
    throw new Error(msg);
  }
};
