import { useState } from "react";
import Button from "@mui/material/Button";

import { CircularProgress, Tooltip } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import {
  ProductionOrder,
  ProductSerial,
  Tracking,
} from "../erp/manufacturing/Product";
import OdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { isBlank } from "../StringUtils";
import { RegisteryBasedProductSerial } from "../RegistryUtils";
import {
  isPackagedProduct,
  needsDeviceId,
  productToGtin,
  productToTracking,
  usesDeviceIdAsSerialNumber,
} from "../Utils";
import {
  CreateSerialRequest,
  CreateSerialResponse,
  createSerial,
} from "@byteflies/byteflies-serials";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { getProductionDate } from "./LabelUtils";

function getSerialOrLot(udi: CreateSerialResponse) {
  if (udi === undefined) {
    throw new Error("udi");
  }
  if (udi !== undefined && udi.serial !== undefined) {
    return udi.serial;
  } else if (udi !== undefined && udi.lot !== undefined) {
    return udi.lot;
  } else {
    throw new Error("serial or lot should be defined");
  }
}

const disabled = (
  productionOrder: ProductionOrder | undefined,
  svc: OdooOperationsService
) => {
  if (
    productionOrder === undefined ||
    productionOrder.finishedSerial === undefined ||
    svc.isOdooProductSerial(productionOrder.finishedSerial)
  ) {
    // there is a serial number already
    return true;
  } else if (getProductionDate(productionOrder) === undefined) {
    return true;
  } else if (isPackagedProduct(productionOrder.product.internalReference)) {
    return true;
  } else if (
    needsDeviceId(productionOrder.product.internalReference) &&
    isBlank(productionOrder.finishedSerial.deviceId)
  ) {
    // for some serial numbers we need a device id
    return true;
  } else {
    return false;
  }
};

interface NewSerialButtonProps {
  productionOrder: ProductionOrder | undefined;
  svc: OdooOperationsService;
  onError(error: any): void;
  onSerialCreated(
    productionOrder: ProductionOrder,
    serial: ProductSerial
  ): void;
}

function NewSerialButton(props: NewSerialButtonProps) {
  const [successState, setSuccessState] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);

  const { svc, productionOrder } = props;

  return (
    <Tooltip title="Create serial number and save in Odoo">
      <span>
        <Button
          data-cy="finished-product-get-new-serial"
          color="primary"
          variant="contained"
          fullWidth
          disabled={disabled(productionOrder, svc) || successState === "busy"}
          onClick={async () => {
            setSuccessState("busy");
            try {
              const gtin = productToGtin(productionOrder!.product);
              const tracking = productToTracking(productionOrder!.product)!;
              const productionDate = getProductionDate(productionOrder!);
              const t =
                tracking === Tracking.ByUniqueSerialNumber ? "serial" : "lot";
              const request: CreateSerialRequest = {
                gtin: gtin,
                productionDate: productionDate || new Date(),
                deviceId: productionOrder!.finishedSerial?.deviceId,
                type: t,
                serial: usesDeviceIdAsSerialNumber(
                  productionOrder!.product.internalReference
                )
                  ? productionOrder?.finishedSerial?.deviceId
                  : undefined,
              };
              const serial = createSerial(request);
              if (serial === undefined || serial.productionDate === undefined) {
                throw new Error("Production date is blank");
              }
              const clonedSerial: RegisteryBasedProductSerial = {
                ...productionOrder!.finishedSerial!,
                udi: serial.udi,
                serial: getSerialOrLot(serial)!,
                gtin: serial.gtin,
                creationDate: serial.productionDate,
              };

              let odooSerial;
              if (t === "serial") {
                odooSerial = await svc.getSerial(
                  productionOrder!.product,
                  serial.serial!
                );
              }

              if (odooSerial) {
                console.log(
                  `Serial ${serial.serial!} already exists, ID: ${
                    odooSerial.id
                  }, reusing this serial`
                );
                const stockQuants = (
                  await svc.searchStockQuantsByLotId(odooSerial.id)
                ).filter((sq) => sq.quantity !== 0);
                if (stockQuants.length !== 0) {
                  throw Error(
                    `Serial has stock quants that are not zero: ${JSON.stringify(
                      stockQuants
                    )}`
                  );
                }
              } else {
                console.log(
                  `Creating new serial ${serial.serial ?? serial.lot!}`
                );
                odooSerial = await svc.createProductSerial(clonedSerial);
              }

              await svc.writeProductionOrder(productionOrder!, odooSerial);
              setSuccessState("success");
              props.onSerialCreated(productionOrder!, odooSerial);
            } catch (error) {
              console.error("Failed to create serial", error);
              setSuccessState("failed");
              props.onError(error);
            }
          }}
        >
          {successState === undefined && <QrCodeIcon />}
          {successState === "busy" && <CircularProgress size="1em" />}
          {successState === "failed" && <Warning />}
          {successState === "success" && <CheckCircle />}
          &nbsp;Create serial
        </Button>
      </span>
    </Tooltip>
  );
}

export default NewSerialButton;
