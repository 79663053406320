import Grid from "@mui/material/Grid";
import {
  CradleTesterJob,
  CradleTesterJobReadIdChipResult,
  CradleTesterJobState,
  CradleTesterJobType,
  PostCradleTesterJobRequest,
} from "../openapi/kitchenaid/cradle-tester/api";
import { KitchenAid } from "../KitchenAid";
import { getStation } from "../Settings";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { QualityCheck } from "../erp/manufacturing/Product";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CheckCircle, Error as ErrorIcon, Memory } from "@mui/icons-material";
import { getCradleTesterDeviceId } from "../CradleTester";
import {
  matrixUrl,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";

interface QualityCheckVerifyCradleIdChipProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  serial: string | undefined;
  gtin: string | undefined;
  version: string | undefined;
  onError(error: Error): void;
  onSuccess(): void;
}

export function QualityCheckVerifyCradleIdChip(
  props: QualityCheckVerifyCradleIdChipProps
) {
  const { svc, qualityCheck, serial, gtin, version, onSuccess, onError } =
    props;

  const [state, setState] = useState<"busy" | "error" | "success" | null>(null);
  const [result, setResult] = useState<CradleTesterJobReadIdChipResult>();

  useEffect(() => {
    if (qualityCheck.state === "pass") {
      setState("success");
    } else if (qualityCheck.state === "fail") {
      setState("error");
    } else {
      setState(null);
    }
  }, [qualityCheck.state]);

  return (
    <Grid container spacing={1} style={{ padding: 10 }}>
      <Grid item xs={10} style={{ paddingBottom: 20 }}>
        <Typography variant="h4">
          {qualityCheckTitleWithoutCode(qualityCheck)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h4">
          <a target="_blank" rel="noreferrer" href={matrixUrl(qualityCheck)}>
            {qualityCheckCode(qualityCheck)}
          </a>
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">Expected</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          size="small"
          InputProps={{
            readOnly: true,
          }}
          label="Serial"
          fullWidth
          value={serial}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          size="small"
          InputProps={{
            readOnly: true,
          }}
          label="GTIN"
          fullWidth
          value={gtin}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          size="small"
          InputProps={{
            readOnly: true,
          }}
          label="Version"
          fullWidth
          value={version}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={1}>
        <Typography variant="h6">Received</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          size="small"
          InputProps={{
            readOnly: true,
          }}
          label="Serial"
          fullWidth
          error={result && result.serial !== serial}
          value={result?.serial ?? ""}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          size="small"
          InputProps={{
            readOnly: true,
          }}
          label="GTIN"
          fullWidth
          error={result && result.gtin !== gtin}
          value={result?.gtin ?? ""}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          size="small"
          InputProps={{
            readOnly: true,
          }}
          label="Version"
          fullWidth
          error={result && result.version !== version}
          value={result?.version ?? ""}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          disabled={state === "busy"}
          color={(() => {
            switch (state) {
              case "success":
                return "success";
              case "error":
                return "error";
              default:
                return "primary";
            }
          })()}
          fullWidth
          startIcon={(() => {
            switch (state) {
              case "success":
                return <CheckCircle />;
              case "error":
                return <ErrorIcon />;
              case "busy":
                return <CircularProgress size="1rem" />;
              default:
                return <Memory />;
            }
          })()}
          onClick={async () => {
            setState("busy");
            try {
              const cradleTester = await getCradleTesterDeviceId();
              const jobRequest: PostCradleTesterJobRequest = {
                deviceId: cradleTester,
                type: CradleTesterJobType.ReadIdChip,
              };
              console.log(`Reading cradle ID chip on device '${cradleTester}'`);
              const station = getStation();
              const test = ["test", "test2"].includes(station) ? true : false;
              const kitchenAid = new KitchenAid(test);
              const jobId = await kitchenAid.createCradleTesterJob(jobRequest);
              console.log(`Cradle tester job ID: ${jobId}`);
              let retries = -1;
              while (retries !== 10) {
                retries++;
                let job: CradleTesterJob;
                try {
                  job = await kitchenAid.readCradleTesterJob(jobId);
                  console.log(
                    `Polling, job result: ${JSON.stringify(job, null, 2)}`
                  );
                } catch (e) {
                  console.error("Polling", e);
                  continue;
                }
                const state = job.state;
                if (state === CradleTesterJobState.Done) {
                  const result = job.readIdChipResult;
                  setResult(result);
                  if (
                    result?.gtin === gtin &&
                    result?.serial === serial &&
                    result?.version === version
                  ) {
                    await svc.saveQualityCheck(qualityCheck, true);
                    qualityCheck.state = "pass";
                    setState("success");
                    onSuccess();
                  } else {
                    qualityCheck.state = "fail";
                    await svc.saveQualityCheck(qualityCheck, false);
                    throw new Error("Cradle ID chip verification failed");
                  }
                  return;
                } else if (state === CradleTesterJobState.Error) {
                  throw new Error("Cradle read ID chip error");
                }
                await new Promise((r) => setTimeout(r, 1000));
              }
              throw new Error("Cradle read ID chip job did not finish in time");
            } catch (e) {
              setState("error");
              onError(e as Error);
            }
          }}
        >
          Verify ID chip
        </Button>
      </Grid>
      <Grid item xs={4} />
    </Grid>
  );
}
