import { getKitchenAidId, getStation } from "./Settings";
import { KitchenAid } from "./KitchenAid";
import { CradleTesterDevice } from "./openapi/kitchenaid/cradle-tester";

const DEVICE_ID = process.env.REACT_APP_CRADLE_TESTER_DEVICE_ID;

export const getCradleTesterDeviceId = async () => {
  if (DEVICE_ID) {
    console.log(
      `Cradle tester device ID set via environmental variable: ${DEVICE_ID}`
    );
    return DEVICE_ID;
  }
  const station = getStation();
  const test = ["test", "test2"].includes(station) ? true : false;
  const kitchenAid = new KitchenAid(test);
  const devices: CradleTesterDevice[] = await kitchenAid
    .listCradleTesterDevices()
    .catch((reason) => {
      throw new Error(reason);
    });
  if (devices.length === 0) {
    throw new Error("No cradle tester devices are available");
  }
  console.log(`
      Found cradle tester devices: ${JSON.stringify(devices, null, 2)}
    `);
  const kitchenAidId = getKitchenAidId(station);
  const kitchenAidDevices = devices.filter(
    (p) => p.kitchenaid === kitchenAidId
  );
  if (kitchenAidDevices.length === 0) {
    throw new Error(
      `No cradle tester devices are available for '${station}' on '${kitchenAidId}'`
    );
  }
  return kitchenAidDevices[0].id;
};
