import {
  Product,
  ProductionOrder,
  ProductionOrderLine,
  ProductSerial,
} from "../erp/manufacturing/Product";
import { DeviceGroup, Label } from "../bff/LABEL_API";
import {
  BF_NAKED_2SNAPCRADLE,
  BF_NAKED_DOCK,
  BF_NAKED_DOT,
  BF_PACKAGED_2SNAPCRADLE,
  BF_PACKAGED_DOT,
  BF_PACKAGED_4WIRE_CRADLE,
  BF_NAKED_4WIRE_CRADLE,
  BF_NAKED_4SNAPCRADLE,
  BF_PACKAGED_4SNAPCRADLE,
  getProductVersion,
  BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD,
  BF_PACKAGED_EEG_ADHESIVE,
  BF_DOUBLE_SIDED_ADHESIVE,
  BF_NAKED_ECG_ADHESIVE,
  BF_NAKED_2SNAPCRADLE_110,
  isCE,
  BF_CARDIOCARE_PATIENT_GUIDE_NL,
  BF_CARDIOCARE_PATIENT_GUIDE_FR,
  BF_CARDIOCARE_PATIENT_DIARY_NL,
  BF_CARDIOCARE_PATIENT_DIARY_FR,
  BF_CARDIOCARE_PATIENT_IDCARD_FR,
  BF_CARDIOCARE_PATIENT_IDCARD_NL,
  BF_NAKED_3SNAPCRADLE_LEFT,
  BF_PACKAGED_3SNAPCRADLE_LEFT,
  BF_NAKED_3SNAPCRADLE_RIGHT,
  BF_PACKAGED_3SNAPCRADLE_RIGHT,
  BF_PACKAGED_DOUBLE_SIDED_ADHESIVE,
  BF_BYTEFLIES_KIT,
  BF_EPICARE_BOX,
  BF_EPICARE_FOCAL_LEFT,
  BF_EPICARE_FOCAL_RIGHT,
  BF_CARDIOCARE_BOX,
  BF_COVIDCARE_BOX_1,
  BF_COVIDCARE_BOX_2,
  getGtinByInternalReference,
  BF_VIRTUAL_BYTEFLIES_KIT,
  BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX,
  BF_NAKED_ECG_3SNAPCRADLE,
  BF_PACKAGED_ECG_3SNAPCRADLE,
  BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR,
  BF_NAKED_ECG_ADHESIVE_MULTI_LEAD_LINEAR,
  BF_NAKED_3WIRECRADLE_LEFT,
  BF_PACKAGED_3WIRECRADLE_LEFT,
  BF_NAKED_3WIRECRADLE_RIGHT,
  BF_PACKAGED_3WIRECRADLE_RIGHT,
  BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR_2PCS,
  BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU,
  BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_2PCS,
  BF_NAKED_EEG_ADHESIVE_LEFT,
  BF_NAKED_EEG_ADHESIVE_RIGHT,
  BF_CARDIOCARE_24H_HOLTER,
  BF_CARDIOCARE_24H_STARTER_KIT,
  isCompoundProduct,
  BF_BYTEFLIES_KIT_MODEL_2,
  BF_VITAL_SIGNS_VS1,
  BF_VITAL_SIGNS_VS2,
  BF_VITAL_SIGNS_VS3,
  BF_VITAL_SIGNS_VS4,
  getInternalReferencesByGtin,
  BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER,
  BF_CARDIOCARE_NON_LINEAR_24H_HOLTER,
  BF_NAKED_ECG_ADHESIVE_SINGLE_LEAD_EXTRA,
} from "../Utils";
import { isBlank, isNotBlank, substringAfter } from "../StringUtils";
import { isLot, parseSerial } from "@byteflies/byteflies-serials";
import OdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import {
  MakeLabelRequest,
  MakeLabelRequestDEVICEEnum,
  MakeLabelRequestINFO,
} from "../openapi/kitchenaid/print";

const mapGroup = function (
  po: ProductionOrder,
  mapper: (line: ProductionOrderLine) => string
) {
  const grp: DeviceGroup = {};
  let dotCount = 0;
  if (po !== undefined && po.lines !== undefined) {
    for (const line of po.lines) {
      const bfNumber = line.product.internalReference;
      switch (bfNumber) {
        case BF_NAKED_DOCK:
          const mappedValue = mapper(line);
          if (mappedValue === undefined) {
            throw new Error(`Unable to map value for ${JSON.stringify(line)}`);
          }
          grp.Dock = mappedValue;
          break;
        case BF_NAKED_2SNAPCRADLE:
        case BF_NAKED_2SNAPCRADLE_110:
        case BF_PACKAGED_2SNAPCRADLE:
          grp["2-Snap-Cradle"] = mapper(line);
          break;
        case BF_NAKED_ECG_3SNAPCRADLE:
        case BF_PACKAGED_ECG_3SNAPCRADLE:
          grp["ECG-3-Snap-Cradle"] = mapper(line);
          break;
        case BF_NAKED_4SNAPCRADLE:
        case BF_PACKAGED_4SNAPCRADLE:
          grp["4-Snap-Cradle"] = mapper(line);
          break;
        case BF_PACKAGED_4WIRE_CRADLE:
        case BF_NAKED_4WIRE_CRADLE:
          grp["ExG Patch"] = mapper(line);
          break;
        case BF_NAKED_DOT:
        case BF_PACKAGED_DOT:
          if (dotCount === 0) {
            grp.Dot = mapper(line);
            grp.Dot1 = mapper(line);
          } else if (dotCount === 1) {
            grp.Dot2 = mapper(line);
          } else if (dotCount === 2) {
            grp.Dot3 = mapper(line);
          } else if (dotCount === 3) {
            grp.Dot4 = mapper(line);
          } else if (dotCount === 4) {
            grp.Dot5 = mapper(line);
          }
          dotCount++;
          break;
        default:
          break;
      }
    }
  }

  const keys = Object.keys(grp);
  if (keys.length === 0) {
    throw new Error(`Unable to map value for ${JSON.stringify(po)}`);
  }
  return grp;
};

const createIDGroup = function (po: ProductionOrder) {
  return mapGroup(po, (line) => {
    if (line.serial !== undefined) {
      return line.serial.deviceId || "";
    }
    return "";
  });
};
const createUDIGroup = function (po: ProductionOrder) {
  return mapGroup(po, (line) => {
    if (line.serial !== undefined) {
      return getUdi(line.serial) || "";
    }
    return "";
  });
};
const createVNGroup = function (po: ProductionOrder) {
  return mapGroup(po, (line) => substringAfter(line.product.name, "- "));
};

const containsOrderLineWithInternalReference = function (
  po: ProductionOrder,
  internalReference: string
) {
  const line = getOrderLineWithInternalReference(po, internalReference);
  return line !== undefined;
};

const getOrderLineWithInternalReference = function (
  po: ProductionOrder,
  internalReference: string
) {
  return po.lines.find((line) => {
    return line.product?.internalReference === internalReference;
  });
};

const isAdhesive = function (product: Product) {
  if (product !== undefined && isNotBlank(product.internalReference)) {
    switch (product.internalReference) {
      case BF_DOUBLE_SIDED_ADHESIVE:
      case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD:
      case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_2PCS:
      case BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR:
      case BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR_2PCS:
      case BF_PACKAGED_EEG_ADHESIVE:
      case BF_NAKED_EEG_ADHESIVE_LEFT:
      case BF_NAKED_EEG_ADHESIVE_RIGHT:
      case BF_NAKED_ECG_ADHESIVE:
      case BF_NAKED_ECG_ADHESIVE_MULTI_LEAD_LINEAR:
        return true;
      default:
        break;
    }
  }

  return false;
};

const adhesiveCount = function (productionOrder: ProductionOrder) {
  if (
    productionOrder === undefined ||
    productionOrder.lines === undefined ||
    productionOrder.quantity <= 0
  ) {
    return 0;
  }

  const adhesives = productionOrder.lines
    .filter((line) => isAdhesive(line.product))
    .filter((line) => line.product_qty !== undefined && line.product_qty > 0)
    .reduce((sum, line) => sum + line.product_qty, 0);

  return Math.round(adhesives / productionOrder.quantity);
};

export const getDockID = async (
  productionOrder: ProductionOrder,
  svc?: OdooOperationsService
): Promise<ProductSerial | undefined> => {
  console.log("Trying to find the dock ID");

  // First try to get the dock ID from the PO serial's internal reference
  console.log("Checking if PO's serial contains a device ID that's a dock ID");
  const deviceId = productionOrder?.finishedSerial?.deviceId;
  if (isNotBlank(deviceId) && deviceId.toLowerCase().startsWith("dock-")) {
    return productionOrder?.finishedSerial;
  }

  // Second, look for a docking station in the PO order lines
  console.log("Checking if the PO lines contain a docking station");
  const dockPoLineFromPo = getOrderLineWithInternalReference(
    productionOrder,
    BF_NAKED_DOCK
  );
  if (
    dockPoLineFromPo &&
    dockPoLineFromPo.serial &&
    isNotBlank(dockPoLineFromPo.serial.deviceId)
  ) {
    return dockPoLineFromPo.serial;
  } else {
    console.log("No docking station found in PO lines");
  }

  // Third, look for a kit with a dock idin the PO order lines
  console.log("Checking if the PO lines contain a kit");
  const kitPoLineFromPo = getOrderLineWithInternalReference(
    productionOrder,
    BF_BYTEFLIES_KIT
  );
  if (
    kitPoLineFromPo &&
    kitPoLineFromPo.serial &&
    isNotBlank(kitPoLineFromPo.serial.deviceId)
  ) {
    return kitPoLineFromPo.serial;
  }

  // Fourth, walk further down the BF kit production order line if svc was passed
  console.log(
    "Checking if we can find a BF kit that contains a docking station"
  );
  if (!svc) {
    console.log("No Odoo service passed, cannot query Odoo");
    return undefined;
  }
  const bfKitPoLineFromPo = getOrderLineWithInternalReference(
    productionOrder,
    BF_BYTEFLIES_KIT
  );
  if (!bfKitPoLineFromPo?.serial.serial) {
    console.log("No BF kit serial found");
    return undefined;
  }
  const bfKitPoBySerialId = await svc.searchProductionOrderBySerialId(
    bfKitPoLineFromPo.serial.id
  );
  if (!bfKitPoBySerialId) {
    console.error("Failed to find BF kit PO");
    return undefined;
  }
  const bfKitPo = await svc.readProductionOrder(bfKitPoBySerialId.id, {
    readQualityChecks: false,
    readLot: false,
    readStockMoves: true,
  });
  const dockPoLineFromBfKit = getOrderLineWithInternalReference(
    bfKitPo,
    BF_NAKED_DOCK
  );
  if (
    dockPoLineFromBfKit &&
    dockPoLineFromBfKit.serial &&
    isNotBlank(dockPoLineFromBfKit.serial.deviceId)
  ) {
    return dockPoLineFromBfKit.serial;
  }
  return undefined;
};

export const getDotID = (
  productionOrder: ProductionOrder
): string | undefined => {
  const deviceId = productionOrder?.finishedSerial?.deviceId;
  if (isNotBlank(deviceId)) {
    return deviceId;
  }

  const dot = getOrderLineWithInternalReference(productionOrder, BF_NAKED_DOT);
  if (
    dot !== undefined &&
    dot.serial !== undefined &&
    isNotBlank(dot.serial.deviceId)
  ) {
    return dot.serial.deviceId;
  }
  return undefined;
};

const getGtin = (productionOrder: ProductionOrder) => {
  const serial = productionOrder!.finishedSerial;
  if (serial === undefined || serial === null) {
    throw new Error("PO serial is not defined");
  }

  let gtin = "";
  if (
    isBlank(gtin) &&
    serial !== undefined &&
    serial.product !== undefined &&
    getGtinByInternalReference(serial.product.internalReference!) !== undefined
  ) {
    gtin = getGtinByInternalReference(serial.product.internalReference!)!;
  }

  const udi = serial.udi;
  if (isBlank(gtin) && isNotBlank(udi) && parseSerial(udi) !== undefined) {
    const p = parseSerial(udi);
    if (p !== undefined && p.gtin !== undefined) {
      gtin = p.gtin;
    }
  }

  if (isBlank(gtin)) {
    console.error(
      "GTIN is not defined",
      "udi",
      udi,
      "gtin",
      gtin,
      "serial",
      JSON.stringify(serial)
    );
    throw new Error("GTIN is not defined");
  }
  return gtin;
};

const getUdi = (serial: ProductSerial) => {
  if (serial === undefined || serial === null) {
    throw new Error("PO serial is not defined");
  }
  const udi = serial.udi;
  if (isNotBlank(udi)) {
    return udi;
  }

  const internalReference = serial.product?.internalReference;
  if (isBlank(internalReference)) {
    throw new Error("PO product BF-number is not defined");
  }
  const gtin = getGtinByInternalReference(internalReference!);
  if (isLot(serial.serial)) {
    return `01${gtin}10${serial.serial}`;
  } else {
    return `01${gtin}21${serial.serial}`;
  }
};

const getExpiryDate = (productionOrder: ProductionOrder) => {
  const productionDate = getProductionDate(productionOrder);
  if (productionDate === undefined) {
    return undefined;
  }
  const expiry = new Date(productionDate);
  expiry.setFullYear(expiry.getFullYear() + 1);
  return expiry;
};

export const getProductionDate = (productionOrder: ProductionOrder) => {
  if (
    productionOrder?.date_planned_start !== undefined &&
    typeof productionOrder.date_planned_start === "object"
  ) {
    return productionOrder.date_planned_start;
  }
  if (
    productionOrder?.finishedSerial?.creationDate !== undefined &&
    typeof productionOrder?.finishedSerial?.creationDate === "object"
  ) {
    return productionOrder?.finishedSerial?.creationDate;
  } else {
    return undefined;
  }
};

export const getBoxID = (serial: ProductSerial) => {
  const s = serial.serial;
  if (isBlank(s)) {
    throw new Error("Serial is blank");
  }
  const boxId = s!.substring(s!.length - 4, s!.length);
  return boxId;
};

const isVitalSignsBox = (po: ProductionOrder) => {
  return [
    BF_VITAL_SIGNS_VS1,
    BF_VITAL_SIGNS_VS2,
    BF_VITAL_SIGNS_VS3,
    BF_VITAL_SIGNS_VS4,
  ].includes(getInternalReferencesByGtin(getGtin(po))[0]);
};

export const createLabels = async function (
  productionOrder: ProductionOrder,
  svc: OdooOperationsService
) {
  const additionalLabelRequests: MakeLabelRequest[] = [];

  const additionalVirtualBfNumbers = [
    BF_VIRTUAL_BYTEFLIES_KIT,
    BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU,
  ];

  const additionalBfNumbers = additionalVirtualBfNumbers.concat([
    BF_NAKED_2SNAPCRADLE_110,
    BF_NAKED_ECG_3SNAPCRADLE,
  ]);

  if (!isCompoundProduct(productionOrder.product.internalReference)) {
    for (const additionalBfNumber of additionalBfNumbers) {
      if (
        isVitalSignsBox(productionOrder) &&
        additionalBfNumber === BF_NAKED_ECG_3SNAPCRADLE
      ) {
        continue;
      }
      const additionalOrderLine = getOrderLineWithInternalReference(
        productionOrder,
        additionalBfNumber
      );
      if (!additionalOrderLine) continue;
      if (!svc) {
        throw new Error("Odoo service is undefined");
      } else if (!additionalOrderLine.serial) {
        throw new Error(`Serial for ${additionalBfNumber} is undefined`);
      } else if (!additionalOrderLine.product) {
        throw new Error(`product for ${additionalBfNumber} is undefined`);
      }
      const additionalPo: ProductionOrder = additionalVirtualBfNumbers.includes(
        additionalBfNumber
      )
        ? ({
            product: additionalOrderLine.product,
            finishedSerial: additionalOrderLine.serial,
          } as ProductionOrder)
        : await (async () => {
            const serialId = additionalOrderLine.serial.id;
            const po = await svc.searchProductionOrderBySerialId(serialId);
            if (po) {
              return po;
            } else {
              throw new Error(
                `Could not find production order for serial ID ${serialId}`
              );
            }
          })();
      const additionalLabel = await createLabel(additionalPo, svc);
      additionalLabelRequests.push(additionalLabel);
    }
  }
  const mainLabel = await createLabel(productionOrder, svc);
  if (isVitalSignsBox(productionOrder)) {
    const serial = productionOrder?.finishedSerial;
    if (serial === undefined || isBlank(serial.serial)) {
      throw new Error(`PO serial is undefined`);
    }
    const info: Label = {
      "vital-signs-barcode": {
        SERIAL: serial.serial,
      },
    };
    const device: MakeLabelRequestDEVICEEnum = "Vital-Signs-Barcode";
    const barcodeLabel: MakeLabelRequest = {
      DEVICE: device,
      INFO: info as any,
    };
    additionalLabelRequests.push(barcodeLabel);
  }
  return additionalLabelRequests
    .filter((l) => l.INFO.GTIN !== mainLabel.INFO.GTIN)
    .concat(mainLabel);
};

export const createLabel = async function (
  productionOrder: ProductionOrder,
  svc?: OdooOperationsService
) {
  console.log(
    `Creating label for production order:\n${JSON.stringify(productionOrder)}`
  );
  const productionDate = getProductionDate(productionOrder);
  if (productionDate === undefined) {
    throw new Error(`Production date is undefined`);
  }
  const serial = productionOrder?.finishedSerial;
  if (serial === undefined || isBlank(serial.serial)) {
    throw new Error(`PO serial is undefined`);
  }

  const expiryDate = getExpiryDate(productionOrder);
  const udi = getUdi(serial);
  if (udi === undefined) {
    throw new Error(`PO serial udi is undefined`);
  }

  const gtin = getGtin(productionOrder);
  const vn = getProductVersion(productionOrder.product);
  if (vn === undefined || isBlank(vn)) {
    throw new Error("Version number is blank");
  }

  if (
    productionOrder === undefined ||
    productionOrder.product === undefined ||
    isBlank(productionOrder.product.internalReference)
  ) {
    throw new Error("Product BF number is blank");
  }

  const info: Label = {
    Date: Date.now(),
    GTIN: gtin,
    SERIAL: serial.serial,
    "BATCH/LOT": isLot(serial.serial) ? serial.serial : undefined,
    "PROD DATE": productionDate,
    "USE BY OR EXPIRY": expiryDate,
    VARIANT: vn,
  };
  let device: MakeLabelRequestDEVICEEnum;

  const bfNumber = productionOrder.product.internalReference!;

  switch (bfNumber) {
    case BF_NAKED_DOT:
    case BF_PACKAGED_DOT:
      const dotId = getDotID(productionOrder);
      if (isBlank(dotId)) {
        throw new Error("Device-ID is blank");
      }
      info["Sensor-Dot"] = {
        UDI: udi,
        ID: dotId!,
        "Version-Number": vn!,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "Sensor-Dot";
      break;
    case BF_NAKED_DOCK:
      const dockId = await getDockID(productionOrder, undefined);
      if (isBlank(dockId?.deviceId)) {
        throw new Error("Device-ID is blank");
      }
      info["Docking-Station"] = {
        UDI: udi,
        ID: dockId?.deviceId!,
        "Version-Number": vn,
      };
      device = "Docking-Station";
      break;
    case BF_NAKED_2SNAPCRADLE:
    case BF_NAKED_2SNAPCRADLE_110:
    case BF_PACKAGED_2SNAPCRADLE:
      info["2-Snap-Cradle"] = {
        UDI: udi,
        "Version-Number": vn,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "2-Snap-Cradle";
      break;
    case BF_NAKED_ECG_3SNAPCRADLE:
    case BF_PACKAGED_ECG_3SNAPCRADLE:
      info["ECG-3-Snap-Cradle"] = {
        "Version-Number": vn,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "ECG-3-Snap-Cradle";
      break;
    case BF_NAKED_3SNAPCRADLE_LEFT:
    case BF_PACKAGED_3SNAPCRADLE_LEFT:
      info["3-Snap-Cradle-Left"] = {
        UDI: udi,
        "Version-Number": vn,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "3-Snap-Cradle-Left";
      break;
    case BF_NAKED_3SNAPCRADLE_RIGHT:
    case BF_PACKAGED_3SNAPCRADLE_RIGHT:
      info["3-Snap-Cradle-Right"] = {
        UDI: udi,
        "Version-Number": vn,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "3-Snap-Cradle-Right";
      break;
    case BF_NAKED_3WIRECRADLE_LEFT:
    case BF_PACKAGED_3WIRECRADLE_LEFT:
      info["3-Wire-Cradle-Left"] = {
        UDI: udi,
        "Version-Number": vn,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "3-Wire-Cradle-Left";
      break;
    case BF_NAKED_3WIRECRADLE_RIGHT:
    case BF_PACKAGED_3WIRECRADLE_RIGHT:
      info["3-Wire-Cradle-Right"] = {
        UDI: udi,
        "Version-Number": vn,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "3-Wire-Cradle-Right";
      break;
    case BF_NAKED_4SNAPCRADLE:
    case BF_PACKAGED_4SNAPCRADLE:
      info["4-Snap-Cradle"] = {
        UDI: udi,
        "Version-Number": vn,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "4-Snap-Cradle";
      break;
    case BF_COVIDCARE_BOX_1:
      const boxIdCCH = productionOrder?.finishedSerial?.deviceId;
      if (isBlank(boxIdCCH)) {
        throw new Error("Device-ID is blank");
      }
      info["CovidCare@Home-box"] = {
        ID: boxIdCCH!,
        "Version-Number": vn,
      };
      device = "CovidCare@Home-box";
      break;
    case BF_PACKAGED_4WIRE_CRADLE:
      info["Patch"] = {
        UDI: udi,
        "Version-Number": vn,
        CE: isCE(productionOrder.product!.internalReference!, vn),
        "Investigational-Use": !isCE(
          productionOrder.product!.internalReference!,
          vn
        ),
      };
      device = "ExG-Patch";
      break;
    case BF_PACKAGED_DOUBLE_SIDED_ADHESIVE:
      info["Package double-sided Adhesive"] = {
        UDI: udi,
        ProductionDate: productionDate.toISOString(),
        ExpirationDate: expiryDate!.toISOString(),
        LotID: serial.serial!,
        "Version-Number": vn,
        ItemCount: adhesiveCount(productionOrder),
        CE: isCE(productionOrder.product!.internalReference!, vn),
      };
      device = "Package double-sided Adhesive";
      break;
    case BF_BYTEFLIES_KIT:
    case BF_VIRTUAL_BYTEFLIES_KIT:
    case BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU:
      info["Kit"] = {
        "UDI-Kit": udi,
        "Version-Number-Kit": vn,
        "ID-Kit": undefined,
      };
      device = "Byteflies-Kit";
      break;
    case BF_BYTEFLIES_KIT_MODEL_2:
      info["Kit"] = {
        "UDI-Kit": udi,
        "Version-Number-Kit": vn,
        "ID-Kit": undefined,
      };
      device = "Byteflies-Kit-Model-2";
      break;
    case BF_EPICARE_BOX:
      info["hw-epicare-box"] = {
        UDI: udi,
        // ID: dockIdECH?.deviceId!,
        "Version-Number": vn,
        Title: "EpiCare@Home",
        SubTitle: "Absence",
        ContentTitle: "EpiCare@Home contents",
        Content: [
          "Byteflies Kit with 2 Sensor Dots",
          "Byteflies Kit Cradle accessory",
          "EEG Adhesives",
          "Double-sided Adhesives",
          "EpiCare@Home UI",
          "EpiCare@Home Assistant App",
        ],
        BoxID: getBoxID(serial),
      };
      device = "EpiCare@Home-box";
      break;
    case BF_EPICARE_FOCAL_LEFT:
      info["hw-epicare-box"] = {
        UDI: udi,
        // ID: dockIdECH?.deviceId!,
        "Version-Number": vn,
        Title: "EpiCare@Home",
        SubTitle: "Focal Left",
        ContentTitle: "EpiCare@Home contents",
        Content: [
          "Byteflies Kit with 4 Sensor Dots",
          "Byteflies Kit Cradle accessories",
          "Certified Electrodes",
          "ECG Adhesives",
          "Double-sided Adhesives",
          "EpiCare@Home UI",
          "EpiCare@Home Assistant App",
        ],
        BoxID: getBoxID(serial),
      };
      device = "EpiCare@Home-box";
      break;
    case BF_EPICARE_FOCAL_RIGHT:
      info["hw-epicare-box"] = {
        UDI: udi,
        // ID: dockIdECH?.deviceId!,
        "Version-Number": vn,
        Title: "EpiCare@Home",
        SubTitle: "Focal Right",
        ContentTitle: "EpiCare@Home contents",
        Content: [
          "Byteflies Kit with 4 Sensor Dots",
          "Byteflies Kit Cradle accessories",
          "Certified Electrodes",
          "ECG Adhesives",
          "Double-sided Adhesives",
          "EpiCare@Home UI",
          "EpiCare@Home Assistant App",
        ],
        BoxID: getBoxID(serial),
      };
      device = "EpiCare@Home-box";
      break;
    case BF_CARDIOCARE_BOX:
      const dockIdCardio = await getDockID(productionOrder, svc);
      if (isBlank(dockIdCardio?.deviceId)) {
        throw new Error("Dock ID is blank");
      } else if (
        dockIdCardio?.deviceId!.toLowerCase().indexOf("dock-") === -1
      ) {
        throw new Error("Dock ID should be a dock ID");
      }
      const content = [
        "Byteflies Kit",
        "ECG Adhesives",
        "2-Snap Cradle",
        "CardioCare@Home Pro",
      ];
      if (
        containsOrderLineWithInternalReference(
          productionOrder,
          BF_CARDIOCARE_PATIENT_GUIDE_NL
        ) ||
        containsOrderLineWithInternalReference(
          productionOrder,
          BF_CARDIOCARE_PATIENT_GUIDE_FR
        )
      ) {
        content.push("Patient Guide");
      }
      if (
        containsOrderLineWithInternalReference(
          productionOrder,
          BF_CARDIOCARE_PATIENT_DIARY_NL
        ) ||
        containsOrderLineWithInternalReference(
          productionOrder,
          BF_CARDIOCARE_PATIENT_DIARY_FR
        )
      ) {
        content.push("Patient Diary");
      }
      if (
        containsOrderLineWithInternalReference(
          productionOrder,
          BF_CARDIOCARE_PATIENT_IDCARD_NL
        ) ||
        containsOrderLineWithInternalReference(
          productionOrder,
          BF_CARDIOCARE_PATIENT_IDCARD_FR
        )
      ) {
        content.push("Patient ID Card");
      }

      info["hw-cardiocare-box"] = {
        UDI: udi,
        ID: dockIdCardio?.deviceId!,
        "Version-Number": vn,
        Title: "CardioCare@Home",
        SubTitle: "",
        ContentTitle: "CardioCare@Home Contents",
        Content: content,
        BoxID: getBoxID(serial),
      };
      device = "CardioCare@Home-box";
      break;
    case BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX:
      const dockIdCardioMultiLeadLinear = await getDockID(productionOrder, svc);
      if (isBlank(dockIdCardioMultiLeadLinear?.deviceId)) {
        throw new Error("Dock ID is blank");
      } else if (
        dockIdCardioMultiLeadLinear
          ?.deviceId!.toLowerCase()
          .indexOf("dock-") === -1
      ) {
        throw new Error("Dock ID should be a dock ID");
      }
      const cardioMultiLeadLinearContent = [
        "Byteflies Kit",
        "ECG Adhesives",
        "CardioCare@Home Pro",
      ];

      info["hw-cardiocare-multi-lead-linear-box"] = {
        ID: dockIdCardioMultiLeadLinear?.deviceId!,
        "Version-Number": vn,
        Title: "CardioCare@Home",
        SubTitle: "Multi Lead Linear",
        ContentTitle: "Procedure Pack Contents",
        Content: cardioMultiLeadLinearContent,
        BoxID: getBoxID(serial),
      };
      device = "CardioCare@Home-Multi-Lead-Linear-box";
      break;
    case BF_CARDIOCARE_24H_STARTER_KIT:
    case BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER:
      const dockIdCardio24hStarterKit = await getDockID(productionOrder, svc);
      if (isBlank(dockIdCardio24hStarterKit?.deviceId)) {
        throw new Error("Dock ID is blank");
      } else if (
        dockIdCardio24hStarterKit?.deviceId!.toLowerCase().indexOf("dock-") ===
        -1
      ) {
        throw new Error("Dock ID should be a dock ID");
      }
      const cardio24hStarterKitContent = [
        "Byteflies Kit",
        "ECG Adhesives",
        "CardioCare@Home Pro",
      ];

      if (bfNumber === BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER) {
        cardio24hStarterKitContent.push("Mobile WiFi Router with PSU");
      }

      info["hw-cardiocare-24h-starter-kit"] = {
        ID: dockIdCardio24hStarterKit?.deviceId!,
        "Version-Number": vn,
        Title: "CardioCare@Home",
        SubTitle:
          bfNumber === BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER
            ? "24h Starter Kit with WiFi Router"
            : "24h Starter Kit",
        ContentTitle: "Procedure Pack Contents",
        Content: cardio24hStarterKitContent,
        BoxID: getBoxID(serial),
      };
      device = "CardioCare@Home-24h-Starter-Kit";
      break;
    case BF_CARDIOCARE_24H_HOLTER:
    case BF_CARDIOCARE_NON_LINEAR_24H_HOLTER:
      info["hw-cardiocare-24h-holter"] = {
        "Version-Number": vn,
        Title:
          bfNumber === BF_CARDIOCARE_NON_LINEAR_24H_HOLTER ? "Non-Linear" : "",
        SubTitle: "24h Holter",
        BoxID: getBoxID(serial),
      };
      const dot = getOrderLineWithInternalReference(
        productionOrder,
        BF_NAKED_DOT
      );
      if (
        dot === undefined ||
        dot.serial.serial === undefined ||
        dot.serial.deviceId === undefined ||
        dot.product.internalReference === undefined
      ) {
        throw new Error("Dot should be defined");
      }
      if (!svc) {
        throw new Error("No Odoo service available, cannot query Odoo");
      }
      const dotPo = await svc.searchProductionOrderBySerialId(dot.serial.id);
      if (!dotPo) {
        throw new Error("Could not find sensor dot PO");
      }
      info["Sensor-Dot"] = {
        ID: dot.serial.deviceId,
        "Version-Number": getProductVersion(dot.product)!,
        CE: true,
        "Investigational-Use": false,
        GTIN: getGtinByInternalReference(dot.product.internalReference),
        SERIAL: dot.serial.serial,
        "PROD DATE": getProductionDate(dotPo),
      };
      const cradle = getOrderLineWithInternalReference(
        productionOrder,
        BF_NAKED_ECG_3SNAPCRADLE
      );
      if (
        cradle === undefined ||
        cradle.serial.serial === undefined ||
        cradle.serial.deviceId === undefined ||
        cradle.product.internalReference === undefined
      ) {
        throw new Error("Cradle should be defined");
      }
      const cradlePo = await svc.searchProductionOrderBySerialId(
        cradle.serial.id
      );
      if (!cradlePo) {
        throw new Error("Could not find cradle PO");
      }
      info["ECG-3-Snap-Cradle"] = {
        "Version-Number": getProductVersion(cradle.product)!,
        CE: true,
        "Investigational-Use": false,
        GTIN: getGtinByInternalReference(cradle.product.internalReference),
        SERIAL: cradle.serial.serial,
        "PROD DATE": getProductionDate(cradlePo),
      };
      const adhesiveBfNumber =
        bfNumber === BF_CARDIOCARE_24H_HOLTER
          ? BF_NAKED_ECG_ADHESIVE_MULTI_LEAD_LINEAR
          : bfNumber === BF_CARDIOCARE_NON_LINEAR_24H_HOLTER
          ? BF_NAKED_ECG_ADHESIVE_SINGLE_LEAD_EXTRA
          : null;
      if (!adhesiveBfNumber) {
        throw new Error("Could not find the right adhesive BF number");
      }
      const adhesive = getOrderLineWithInternalReference(
        productionOrder,
        adhesiveBfNumber
      );
      if (
        adhesive === undefined ||
        adhesive.serial.serial === undefined ||
        adhesive.product.internalReference === undefined
      ) {
        throw new Error("Adhesive should be defined");
      }
      const adhesiveProdDate = getProductionDate(productionOrder);
      if (!adhesiveProdDate) {
        throw new Error("Adhesive production date undefined");
      }
      const adhesiveExpiryDate = getExpiryDate(productionOrder);
      if (!adhesiveExpiryDate) {
        throw new Error("Adhesive expiry date undefined");
      }
      const adhesiveInfoKey =
        adhesiveBfNumber === BF_NAKED_ECG_ADHESIVE_MULTI_LEAD_LINEAR
          ? "Package ECG Adhesive Multi Lead Linear"
          : adhesiveBfNumber === BF_NAKED_ECG_ADHESIVE_SINGLE_LEAD_EXTRA
          ? "Package ECG Adhesive Single Lead Extra"
          : null;
      if (!adhesiveInfoKey) {
        throw new Error("Adhesive info key not configured");
      }
      info[adhesiveInfoKey] = {
        ProductionDate: adhesiveProdDate.getTime() / 1000,
        ExpirationDate: adhesiveExpiryDate.getTime() / 1000,
        LotID: adhesive.serial.serial,
        "Version-Number": getProductVersion(adhesive.product)!,
        ItemCount: adhesiveCount(productionOrder),
        CE: true,
        GTIN: getGtinByInternalReference(adhesive.product.internalReference),
      };
      device = "CardioCare@Home-24h-Holter";
      break;
    case BF_COVIDCARE_BOX_2:
      const dock = getOrderLineWithInternalReference(
        productionOrder,
        BF_NAKED_DOCK
      );
      if (
        dock === undefined ||
        dock.serial === undefined ||
        dock.serial.deviceId === undefined
      ) {
        throw new Error("Dock should be defined");
      }
      info["CovidCare@Home-box-H1"] = {
        "UDI-Kit": udi,
        "Version-Number-Kit": vn,
        UDI: createUDIGroup(productionOrder),
        ID: createIDGroup(productionOrder),
        "Version-Number": createVNGroup(productionOrder),
      };
      info["Docking-Station"] = {
        UDI: getUdi(dock.serial) || "",
        ID: dock.serial.deviceId!,
        "Version-Number": vn,
      };
      info["Kit"] = {
        "UDI-Kit": udi,
        "Version-Number-Kit": vn,
        "ID-Kit": dock.serial.deviceId!,
      };
      device = "CovidCare@Home-box-H1";
      break;
    case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD:
    case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_2PCS:
      info["Package ECG Adhesive Single Lead"] = {
        UDI: udi,
        ProductionDate: productionDate.getTime() / 1000,
        ExpirationDate: expiryDate!.getTime() / 1000,
        LotID: serial.serial!,
        "Version-Number": vn,
        ItemCount: adhesiveCount(productionOrder),
        CE: isCE(productionOrder.product!.internalReference!, vn),
      };
      device = "Package ECG Adhesive Single Lead";
      break;
    case BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR:
    case BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR_2PCS:
      info["Package ECG Adhesive Multi Lead Linear"] = {
        UDI: udi,
        ProductionDate: productionDate.getTime() / 1000,
        ExpirationDate: expiryDate!.getTime() / 1000,
        LotID: serial.serial!,
        "Version-Number": vn,
        ItemCount: adhesiveCount(productionOrder),
        CE: isCE(productionOrder.product!.internalReference!, vn),
      };
      device = "Package ECG Adhesive Multi Lead Linear";
      break;
    case BF_PACKAGED_EEG_ADHESIVE:
      info["Package EEG Adhesive"] = {
        UDI: udi,
        ProductionDate: productionDate.getTime() / 1000,
        ExpirationDate: expiryDate!.getTime() / 1000,
        LotID: serial.serial!,
        "Version-Number": vn,
        ItemCount: adhesiveCount(productionOrder),
        CE: isCE(productionOrder.product!.internalReference!, vn), //vn.toLowerCase() === "4b" ? false : true,
      };
      device = "Package EEG Adhesive";
      break;

    case BF_VITAL_SIGNS_VS1:
    case BF_VITAL_SIGNS_VS2:
    case BF_VITAL_SIGNS_VS3:
    case BF_VITAL_SIGNS_VS4:
      const dockIdVitalSigns = await getDockID(productionOrder, svc);
      if (isBlank(dockIdVitalSigns?.deviceId)) {
        throw new Error("Dock ID is blank");
      } else if (
        dockIdVitalSigns?.deviceId!.toLowerCase().indexOf("dock-") === -1
      ) {
        throw new Error("Dock ID should be a dock ID");
      }

      info["vital-signs-box"] = {
        ID: dockIdVitalSigns?.deviceId!,
        "Version-Number": vn,
        BoxID: getBoxID(serial),
      };
      device = "Vital-Signs-box";
      break;

    default:
      throw new Error(`Unknown device type: ${bfNumber}`);
  }

  const request: MakeLabelRequest = {
    DEVICE: device as MakeLabelRequestDEVICEEnum,
    INFO: info as any as MakeLabelRequestINFO,
  };
  return request;
};
