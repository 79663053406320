import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import {
  ProductSerial,
  QualityCheck,
  ProductionOrder,
} from "../erp/manufacturing/Product";
import {
  matrixUrl,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";
import { Button, TextField, Typography } from "@mui/material";
import RedButton from "../actions/RedButton";
import GreenButton from "../actions/GreenButton";
import WriteNfcTagButton from "../actions/WriteNfcTagButton";
import { isBlank } from "../StringUtils";
import { GetNfcResponse } from "../bff/NFC_API_READ";
import { useState } from "react";
import { getIdFromResponse, convertNfcResponse } from "../GetNfcDialog";
import { readNfc } from "../Nfc";
import { getStation } from "../Settings";

interface QualityCheckWriteNfcTagProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  serial: ProductSerial;
  productionOrder: ProductionOrder;
  onError(error: Error): void;
  onSuccess(): void;
  onFail(): void;
}

function QualityCheckWriteNfcTag(props: QualityCheckWriteNfcTagProps) {
  const {
    svc,
    qualityCheck,
    serial,
    productionOrder,
    onSuccess,
    onFail,
    onError,
  } = props;

  const [readNfcTag, setReadNfcTag] = useState<GetNfcResponse | undefined>(
    undefined
  );

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4">
            {qualityCheckTitleWithoutCode(qualityCheck)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {qualityCheck !== undefined && qualityCheck.id !== undefined && (
            <Typography variant="h4">
              <a
                target="_blank"
                rel="noreferrer"
                href={matrixUrl(qualityCheck)}
              >
                {qualityCheckCode(qualityCheck)}
              </a>
            </Typography>
          )}
        </Grid>

        <Grid item xs={2}>
          {serial !== undefined && productionOrder !== undefined && (
            <WriteNfcTagButton
              productionOrder={productionOrder}
              qualityCheck={qualityCheck}
              onError={(err) => onError(err)}
              onSuccess={async () => {
                try {
                  setReadNfcTag(undefined);
                  const station = getStation();
                  const ndefMsg = await readNfc(station);
                  console.log(`NDEF message: ${JSON.stringify(ndefMsg)}`);
                  const nfc: GetNfcResponse = Object.assign(
                    {},
                    ...ndefMsg.records.map((r) => ({ [r.mediaType!]: r.data }))
                  );
                  setReadNfcTag(nfc);
                } catch (error) {
                  setReadNfcTag(undefined);
                }
              }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            disabled={true}
            label="Serial"
            fullWidth
            value={
              isBlank(productionOrder?.finishedSerial?.serial)
                ? ""
                : productionOrder?.finishedSerial?.serial
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            disabled={true}
            label="Device ID"
            fullWidth
            value={
              isBlank(productionOrder?.finishedSerial?.deviceId)
                ? ""
                : productionOrder?.finishedSerial?.deviceId
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Place the device on the NFC writer and write the NFC tag
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Button
            data-cy="read-nfc"
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={false}
            onClick={async () => {
              try {
                setReadNfcTag(undefined);
                const station = getStation();
                const ndefMsg = await readNfc(station);
                console.log(`NDEF message: ${JSON.stringify(ndefMsg)}`);
                const nfc: GetNfcResponse = Object.assign(
                  {},
                  ...ndefMsg.records.map((r) => ({ [r.mediaType!]: r.data }))
                );
                setReadNfcTag(nfc);
              } catch (e) {
                const msg = "Failed to read NFC";
                console.error(msg, e);
                setReadNfcTag(undefined);
              }
            }}
          >
            Read NFC
          </Button>
        </Grid>

        <Grid item xs={2}>
          <TextField
            size="small"
            disabled={true}
            label="Serial"
            fullWidth
            value={
              readNfcTag === undefined
                ? "-"
                : convertNfcResponse(readNfcTag).serial
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            disabled={true}
            label="Device ID"
            fullWidth
            value={
              readNfcTag === undefined ? "-" : getIdFromResponse(readNfcTag)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>Read the NFC tag</Typography>
        </Grid>

        <Grid item xs={6}>
          <RedButton
            data-cy="fail"
            fullWidth
            label="Fail"
            disabled={qualityCheck === undefined || serial === undefined}
            onClick={async () => {
              try {
                await svc.saveQualityCheck(qualityCheck!, false);
                qualityCheck!.state = "fail";
                onFail();
              } catch (error) {
                console.error(
                  "Failed to save quality check",
                  error,
                  qualityCheck
                );
                onError(error as Error);
              }
            }}
            onError={(error) => {
              onError(error as Error);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <GreenButton
            qualityCheckCode={qualityCheckCode(qualityCheck)}
            label="Pass"
            fullWidth
            disabled={qualityCheck === undefined || serial === undefined}
            onClick={async () => {
              try {
                await svc.saveQualityCheck(qualityCheck!, true);
                qualityCheck!.state = "pass";
                onSuccess();
              } catch (error) {
                console.error(
                  "Failed to save quality check",
                  error,
                  qualityCheck
                );
                onError(error as Error);
              }
            }}
            onError={(error) => {
              onError(error as Error);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default QualityCheckWriteNfcTag;
