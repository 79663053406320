import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import IOdooOperationsService from "./erp/manufacturing/OdooOperationsService";
import { ProductionOrder, QualityCheck } from "./erp/manufacturing/Product";
import QualityCheckBluetoothStrength from "./quality_checks/QualityCheckBluetoothStrength";
import QualityCheckDockTest from "./quality_checks/QualityCheckDockTest";
import QualityCheckPassFail from "./quality_checks/QualityCheckPassFail";
import {} from "./Plot/cloudApi";
import {
  CARDIOCARE_GROUP,
  EPICARE_GROUP,
  qualityCheckCode,
  getGtinByInternalReference,
  getProductVersion,
  isScrappableQualityCheck,
  CARDIOCARE_STARTER_KITS_GROUP,
} from "./Utils";
import {
  WI_SNIPEIT,
  WI_WRITE_NFC_TAG,
  WI_DOT_SIGNAL_QUALITY,
  WI_BLE_TEST,
  WI_CONFIGURE_DOCK_CARDIO,
  WI_CONFIGURE_DOCK_EPICARE,
  WI_CONFIGURE_EEG_DOTS,
  WI_CONFIGURE_ECG_DOTS_EPICARE,
  WI_DOCK_TEST,
  WI_PRINT_LABEL,
  WI_CONFIGURE_ECG_DOTS,
  WI_CONFIGURE_ECG_DOTS_DUAL_CHANNEL,
  WI_TEST_CRADLE_RESISTANCES,
  WI_HARDWARE_VERSION_IDENTIFICATION,
  WI_WRITE_CRADLE_ID_CHIP,
  WI_CONFIGURE_EPICARE_FOCAL_BOX_DOTS,
  WI_CONFIGURE_DOCK_CARDIO_STARTER_KITS,
} from "./WorkInstructions";
import MarkAsDoneButton from "./actions/MarkAsDoneButton";
import QualityCheckSnipeIt from "./quality_checks/QualityCheckSnipeIt";
import QualityCheckWriteNfcTag from "./quality_checks/QualityCheckWriteNfcTag";
import QualityCheckTestSensorDot from "./quality_checks/QualityCheckTestSensorDot";
import QualityCheckPrintLabel from "./quality_checks/QualityCheckPrintLabel";
import QualityCheckAddDevicesToGroup from "./quality_checks/QualityCheckAddDevicesToGroup";
import { QualityCheckTestCradle } from "./quality_checks/QualityCheckTestCradle";
import QualityCheckHardwareVersion from "./quality_checks/QualityCheckHardwareVersion";
import { QualityCheckWriteCradleIdChip } from "./quality_checks/QualityCheckWriteCradleIdChip";
import { useHistory } from "react-router";
import { QualityAlertButton } from "./actions/QualityAlertButton";
import { id } from "./erp/odoo/OdooUtils";
import { ScrapButton } from "./actions/ScrapButton";

interface AssemblyStepProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  productionOrder: ProductionOrder;
  onChange: (productionOrder: ProductionOrder) => void;
}
function AssemblyStep(props: AssemblyStepProps) {
  const { productionOrder, qualityCheck, svc, onChange } = props;
  const [open, setOpen] = React.useState(false);
  const [messageSnackbar, setmessageSnackbar] = useState("");

  const handleSnackbar = (message: string) => {
    setmessageSnackbar(message);
    setOpen(true);
  };
  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const history = useHistory();

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      {qualityCheckCode(qualityCheck) === WI_BLE_TEST && (
        <QualityCheckBluetoothStrength
          qualityCheck={qualityCheck}
          deviceId={
            productionOrder.finishedSerial === undefined
              ? undefined
              : productionOrder.finishedSerial.deviceId
          }
          svc={svc}
          onError={(error) => {
            console.error("Failed to scrap", error);
            handleSnackbar("Failed to scrap");
          }}
          onSuccess={() => {
            onChange({ ...productionOrder });
          }}
          onFail={() => {
            onChange({ ...productionOrder });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) === WI_DOT_SIGNAL_QUALITY && (
        <QualityCheckTestSensorDot
          qualityCheck={qualityCheck}
          serial={productionOrder.finishedSerial}
          svc={svc}
          onError={(error) => {
            console.error("Failed to pass dot signal quality", error);
            handleSnackbar("Failed to pass dot signal quality");
          }}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
          onFail={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) ===
        WI_HARDWARE_VERSION_IDENTIFICATION && (
        <QualityCheckHardwareVersion
          qualityCheck={qualityCheck}
          serial={productionOrder.finishedSerial}
          svc={svc}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
          onFail={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) === WI_DOCK_TEST && (
        <QualityCheckDockTest
          description="Test that all 5 slots can be used."
          qualityCheck={qualityCheck}
          serial={productionOrder.finishedSerial}
          minimumDots={5}
          removeFromGroupEnabled={true}
          svc={svc}
          group={{
            id: "4d0912f0-4443-11eb-b21a-59854bd51688",
            description: "manufacturing group",
            name: "1_Manufacturing_Dock",
          }}
          onError={(error) => {
            console.error("Failed to pass dock test", error);
            handleSnackbar("Failed to pass dock test");
          }}
          onSuccess={() => {
            onChange({ ...productionOrder });
          }}
          onFail={() => {
            onChange({ ...productionOrder });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) === WI_CONFIGURE_DOCK_EPICARE && (
        <QualityCheckAddDevicesToGroup
          productionOrder={productionOrder}
          qualityCheck={qualityCheck}
          svc={svc}
          group={EPICARE_GROUP}
          onError={(error) => {
            console.error("Failed to configure dock for EpiCare", error);
            handleSnackbar("Failed to configure dock for EpiCare");
          }}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
          onFail={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) === WI_CONFIGURE_DOCK_CARDIO && (
        <QualityCheckAddDevicesToGroup
          qualityCheck={qualityCheck}
          productionOrder={productionOrder}
          group={CARDIOCARE_GROUP}
          svc={svc}
          onError={(error) => {
            console.error("Failed to configure dock", error);
            handleSnackbar("Failed to configure dock");
          }}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
          onFail={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) ===
        WI_CONFIGURE_DOCK_CARDIO_STARTER_KITS && (
        <QualityCheckAddDevicesToGroup
          qualityCheck={qualityCheck}
          productionOrder={productionOrder}
          group={CARDIOCARE_STARTER_KITS_GROUP}
          svc={svc}
          onError={(error) => {
            console.error("Failed to configure dock", error);
            handleSnackbar("Failed to configure dock");
          }}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
          onFail={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) === WI_SNIPEIT && (
        <QualityCheckSnipeIt
          qualityCheck={qualityCheck}
          serial={productionOrder.finishedSerial}
          productionOrder={productionOrder}
          svc={svc}
          onError={(error) => {
            console.error("Failed to register asset in Snipe-IT", error);
            handleSnackbar("Failed to register asset in Snipe-IT");
          }}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
          onFail={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) === WI_PRINT_LABEL && (
        <QualityCheckPrintLabel
          qualityCheck={qualityCheck}
          serial={productionOrder.finishedSerial!}
          svc={props.svc}
          productionOrder={productionOrder}
          onError={(error) => {
            const msg = "Failed to print label";
            console.error(msg, error);
            handleSnackbar(`${msg}: ${error}`);
          }}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
          onFail={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) === WI_WRITE_NFC_TAG &&
        productionOrder.finishedSerial !== undefined && (
          <QualityCheckWriteNfcTag
            qualityCheck={qualityCheck}
            serial={productionOrder.finishedSerial}
            productionOrder={productionOrder}
            svc={svc}
            onError={(error) => {
              console.error("Failed to write NFC tag", error);
              handleSnackbar("Failed to write NFC tag");
            }}
            onSuccess={() => {
              onChange({
                ...productionOrder,
                qualityChecks: [...productionOrder.qualityChecks],
              });
            }}
            onFail={() => {
              onChange({
                ...productionOrder,
                qualityChecks: [...productionOrder.qualityChecks],
              });
            }}
          />
        )}

      {qualityCheckCode(qualityCheck) === WI_TEST_CRADLE_RESISTANCES && (
        <QualityCheckTestCradle
          qualityCheck={qualityCheck}
          svc={svc}
          onError={(error) => {
            handleSnackbar(error.message);
          }}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
          onFail={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) === WI_WRITE_CRADLE_ID_CHIP && (
        <QualityCheckWriteCradleIdChip
          qualityCheck={qualityCheck}
          svc={svc}
          serial={productionOrder.finishedSerial?.serial}
          gtin={getGtinByInternalReference(
            productionOrder.product.internalReference ?? ""
          )}
          version={getProductVersion(productionOrder.product)}
          prodDate={
            productionOrder?.finishedSerial?.creationDate ||
            productionOrder?.date_planned_start
          }
          onError={(error) => {
            handleSnackbar(error.message);
          }}
          onSuccess={() => {
            onChange({
              ...productionOrder,
              qualityChecks: [...productionOrder.qualityChecks],
            });
          }}
        />
      )}

      {qualityCheckCode(qualityCheck) !== WI_DOT_SIGNAL_QUALITY &&
        qualityCheckCode(qualityCheck) !== WI_DOCK_TEST &&
        qualityCheckCode(qualityCheck) !== WI_HARDWARE_VERSION_IDENTIFICATION &&
        qualityCheckCode(qualityCheck) !== WI_CONFIGURE_DOCK_EPICARE &&
        qualityCheckCode(qualityCheck) !== WI_CONFIGURE_ECG_DOTS_EPICARE &&
        qualityCheckCode(qualityCheck) !== WI_CONFIGURE_EEG_DOTS &&
        qualityCheckCode(qualityCheck) !== WI_CONFIGURE_ECG_DOTS_DUAL_CHANNEL &&
        qualityCheckCode(qualityCheck) !==
          WI_CONFIGURE_EPICARE_FOCAL_BOX_DOTS &&
        qualityCheckCode(qualityCheck) !== WI_CONFIGURE_DOCK_CARDIO &&
        qualityCheckCode(qualityCheck) !==
          WI_CONFIGURE_DOCK_CARDIO_STARTER_KITS &&
        qualityCheckCode(qualityCheck) !== WI_CONFIGURE_ECG_DOTS &&
        qualityCheckCode(qualityCheck) !== WI_BLE_TEST &&
        qualityCheckCode(qualityCheck) !== WI_SNIPEIT &&
        qualityCheckCode(qualityCheck) !== WI_WRITE_NFC_TAG &&
        qualityCheckCode(qualityCheck) !== WI_TEST_CRADLE_RESISTANCES &&
        qualityCheckCode(qualityCheck) !== WI_WRITE_CRADLE_ID_CHIP &&
        qualityCheckCode(qualityCheck) !== WI_PRINT_LABEL && (
          <QualityCheckPassFail
            qualityCheck={qualityCheck}
            svc={svc}
            onError={(error) => {
              console.error("Failed quality check", error);
              handleSnackbar("Failed quality check");
            }}
            onSuccess={() => {
              onChange({
                ...productionOrder,
                qualityChecks: [...productionOrder.qualityChecks],
              });
            }}
            onFail={() => {
              onChange({
                ...productionOrder,
                qualityChecks: [...productionOrder.qualityChecks],
              });
            }}
          />
        )}

      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>

        <Grid item xs={4}>
          <QualityAlertButton
            svc={svc}
            serials={[productionOrder?.finishedSerial!]}
            srcLocationId={id(productionOrder?.location_dest_id)!}
            qualityCheckId={qualityCheck.id}
            qualityCheckTitle={qualityCheck.title ?? ""}
            onClick={async () => {
              const clone: ProductionOrder = {
                ...productionOrder!,
              };
              try {
                const odooClient = svc.getOdooClient();
                const odooSession = await svc.getOdooSession();
                const qcIdsToUnlink = productionOrder?.qualityChecks
                  .filter((qc) => qc.state === "none")
                  .map((qc) => qc.id);
                if (qcIdsToUnlink && qcIdsToUnlink.length > 0) {
                  await odooClient.unlink(
                    odooSession,
                    qcIdsToUnlink,
                    "quality.check"
                  );
                }
                clone.qualityChecks = clone.qualityChecks.filter(
                  (qc) => !qcIdsToUnlink?.includes(qc.id)
                );
                if (clone.state !== "done") {
                  const result = await props.svc.markStockProductionAsDone(
                    productionOrder!,
                    false
                  );
                  if (typeof result === "object") {
                    console.error("Mark as done error", JSON.stringify(result));
                    throw new Error("Mark as done error");
                  }
                  clone.state = "done";
                }
              } finally {
                onChange(clone);
              }
            }}
            disabled={
              isScrappableQualityCheck(qualityCheck) ||
              qualityCheck.state !== "fail" ||
              productionOrder.state === "done" ||
              !productionOrder?.finishedSerial ||
              !productionOrder.qualityChecks.some(
                (qc) => qc.state === "fail"
              ) ||
              !productionOrder.qualityChecks
                .filter((qc) =>
                  [WI_WRITE_NFC_TAG, WI_PRINT_LABEL].includes(
                    qualityCheckCode(qc)
                  )
                )
                .every((qc) => qc.state && ["pass", "fail"].includes(qc.state))
            }
            onError={(error) => {
              console.error("Failed to create quality alert", error);
              handleSnackbar("Failed to create quality alert");
            }}
            onDone={() => {}}
          />
        </Grid>
        <Grid item xs={4}>
          <ScrapButton
            svc={svc}
            serials={[productionOrder?.finishedSerial!]}
            srcLocationId={id(productionOrder?.location_dest_id)!}
            onClick={async () => {
              const clone: ProductionOrder = {
                ...productionOrder!,
              };
              try {
                const odooClient = svc.getOdooClient();
                const odooSession = await svc.getOdooSession();
                const qcIdsToUnlink = productionOrder?.qualityChecks
                  .filter((qc) => qc.state === "none")
                  .map((qc) => qc.id);
                if (qcIdsToUnlink && qcIdsToUnlink.length > 0) {
                  await odooClient.unlink(
                    odooSession,
                    qcIdsToUnlink,
                    "quality.check"
                  );
                }
                clone.qualityChecks = clone.qualityChecks.filter(
                  (qc) => !qcIdsToUnlink?.includes(qc.id)
                );
                if (clone.state !== "done") {
                  const result = await props.svc.markStockProductionAsDone(
                    productionOrder!,
                    false
                  );
                  if (typeof result === "object") {
                    console.error("Mark as done error", JSON.stringify(result));
                    throw new Error("Mark as done error");
                  }
                  clone.state = "done";
                }
              } finally {
                onChange(clone);
              }
            }}
            disabled={
              !isScrappableQualityCheck(qualityCheck) ||
              productionOrder.state === "done" ||
              qualityCheck.state !== "fail" ||
              !productionOrder?.finishedSerial ||
              !productionOrder.qualityChecks.some(
                (qc) => qc.state === "fail"
              ) ||
              !productionOrder.qualityChecks
                .filter((qc) =>
                  [WI_WRITE_NFC_TAG, WI_PRINT_LABEL].includes(
                    qualityCheckCode(qc)
                  )
                )
                .every((qc) => qc.state && ["pass", "fail"].includes(qc.state))
            }
            onError={(error) => {
              console.error("Failed to scrap", error);
              handleSnackbar(`Failed to scrap: ${error}`);
            }}
            onDone={() => {}}
          />
        </Grid>
        <Grid item xs={4}>
          <MarkAsDoneButton
            svc={svc}
            productionOrder={productionOrder}
            onError={(error) => {
              console.error("Failed to mark as done", error);
              handleSnackbar(`Failed to mark as done: ${error}`);
            }}
            onDone={(po) => {
              onChange(po);
              history.push("/");
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={messageSnackbar}
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              onClick={(event) => {
                handleClose(event, "");
              }}
            >
              UNDO
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default AssemblyStep;
